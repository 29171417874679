import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&.Mui-focused': {
            color: '#fff',
          },
        },
      },
    },
  },
});
const AcceptOfferButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#6c63ff',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#5a52d4',
  },
}));

const DeclineOfferButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: '#fff',
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '70vh',
  padding: theme.spacing(2),
  backgroundColor: '#1a1a2e',
  color: '#fff',
  width: '100%', // Ensure full width
}));

const OfferCard = styled(Card)(({ theme }) => ({
  maxWidth: 800, // Increase max-width
  width: '100%',
  backgroundColor: '#162447',
  color: '#fff',
  textAlign: 'center',
  margin: theme.spacing(2, 0),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1, 0),
    width: '100%', // Adjust width for smaller screens
  },
}));
function App() {
  const { device_id, offer_id } = useParams();
  const [offerAccepted, setOfferAccepted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [step, setStep] = useState(1); // 1: Enter phone number, 2: Enter confirmation code, 3: Offer accepted

  const sendConfirmationCode = async () => {
    try {
      // Simulate sending confirmation code
      console.log(`Sending confirmation code to ${phoneNumber}`);
      setStep(2);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmCode = async () => {
    try {
      // Simulate confirming the code
      console.log(`Confirming code ${confirmationCode} for ${phoneNumber}`);
      // await axios.post(`https://wuxtptuuqf.execute-api.us-east-1.amazonaws.com/dev/offers/${offer_id}/accept`, {
      //   deviceMSISDN: device_id
      // }, {
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // });
      setOfferAccepted(true);
      setStep(3);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
      <Box sx={{ maxWidth: 600, width: '100%', textAlign: 'center', margin: '0 auto 5rem', padding: '0 1rem' }}>
        <Typography variant="h4" gutterBottom>
          قم بتعبئة 500 جنيه سوداني واحصل على 200 دقيقة مجانية
        </Typography>
      </Box>   
      <OfferCard>
        <CardContent>
        <Typography variant="h5" gutterBottom>
  {step === 1 && 'أدخل رقم هاتفك لتأكيد العرض'}
  {step === 2 && 'أدخل رمز التأكيد'}
  {step === 3 && (
    <>
    تم قبول العرض<br />
    استمتع بخطتك الجديدة
  </>
  )}
</Typography>
          {step === 1 && (
            <TextField
              label="أدخل رقم هاتفك"
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          {step === 2 && (
            <TextField
              label="أدخل رمز التأكيد"
              variant="outlined"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
        </CardContent>
        <CardActions>
          {step === 1 && (
            <AcceptOfferButton variant="contained" onClick={sendConfirmationCode} fullWidth>
              إرسال رمز التأكيد
            </AcceptOfferButton>
          )}
          {step === 2 && (
            <AcceptOfferButton variant="contained" onClick={confirmCode} fullWidth>
              تأكيد الرمز
            </AcceptOfferButton>
          )}

        </CardActions>
      </OfferCard>
    </Container>
    </ThemeProvider>

  );
}

export default App;